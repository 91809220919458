import { SortPayload } from "react-admin";
import { dataProvider } from "./dataProvider";

/**
 * Interface for the data request parameters.
 * @interface IData
 * @property {string} key - Property name to check when filtering the data.
 * @property {any[]} csv - CSV data as array.
 * @property {string} apiResource - The API resource to use for the request.
 * @property {string} entityProp - Column name in the database table.
 * @property {object} [pagination] - Pagination parameters.
 * @property {number} [pagination.page] - Page number.
 * @property {number} [pagination.perPage] - Number of items per page.
 * @property {object} [sort] - Sort parameters.
 * @property {string} [sort.field] - Column name to sort by.
 * @property {string} [sort.order] - Sort order.
 */
interface IData {
  key: string;
  csv: any[];
  apiResource: string;
  entityProp: string;
  pagination?: { page: number; perPage: number };
  sort?: SortPayload;
}

const dataRequest = {
  /**
   * Retrieves a list of data based on the provided parameters.
   * @param iData - The data request parameters.
   * @returns A Promise that resolves to the retrieved data.
   */
  getList: async (iData: IData) => {
    /**
     * An array containing unique values extracted from the 'csv' property of 'iData'.
     * @type {Array<any>}
     */
    const searchValues = iData.csv
      .map((row) => row[iData.key])
      .filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      );

    /**
     * The retrieved data.
     * @type {any}
     */
    const { data } = await dataProvider.getList(iData.apiResource, {
      pagination: iData.pagination || { page: 1, perPage: 100 },
      sort: iData.sort || { field: "id", order: "ASC" },
      filter: { [iData.entityProp]: searchValues },
    });

    return data;
  },
};

export default dataRequest;
